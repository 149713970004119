export const countries = [
  {
    label: "England",
    id: "1",
  },
  {
    label: "Scotland",
    id: "2",
  },
  {
    label: "Wales",
    id: "3",
  },
  {
    label: "Northern Ireland",
    id: "4",
  },
];
