import { IStore } from "helpers";
import { createSlice } from "@reduxjs/toolkit";

export const reportReducer = createSlice({
  name: "report store",
  initialState: {
    listSite: [],
  },
  reducers: {
    setListSiteAvailable: (state, action) => {
      state.listSite = action.payload;
    },
  },
});
export const { setListSiteAvailable } = reportReducer.actions;

export const getListSiteAvailable = (state: IStore) => state.report.listSite;

export default reportReducer.reducer;
