import { IStore } from "helpers";
import { createSlice } from "@reduxjs/toolkit";

export const notificationReducer = createSlice({
  name: "notification store",
  initialState: {
    generatedReport: null,
    alertToLotEmailCms: null,
    editedFarmGroupId: null,
    timeStamp : null,
    clientId: null
  },
  reducers: {
    newGeneratedReport: (state, action) => {
      state.generatedReport = action.payload;
    },
    newAlertToLotEmailCms: (state, action) => {
      state.alertToLotEmailCms = action.payload;
    },
    setEditedFarmGroupId: (state, action) => {
      state.editedFarmGroupId = action.payload;
    },
    setTimeStamp: (state, action) => {
      state.timeStamp = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
});
export const { newGeneratedReport, newAlertToLotEmailCms, setEditedFarmGroupId, setTimeStamp,  setClientId } =
  notificationReducer.actions;

export const getNewNotification = (state: IStore) =>
  state.notification.generatedReport;

export const getNewAlertToLotEmailCms = (state: IStore) =>
  state.notification.alertToLotEmailCms;

export const getEditedFarmGroupId = (state: IStore) =>
  state.notification.editedFarmGroupId;

export const getTimeStamp = (state: IStore) =>
  state.notification.timeStamp;
export const getClientId = (state: IStore) =>
  state.notification.clientId;
export default notificationReducer.reducer;
