export interface CreateActivityReportInput {
  type: string;
  startDate: number;
  endDate: number;
  country: string;
  site: string;
  name: string;
  saveReport: boolean;
  subTitle?: string;
  typeId: number;
  species: string;
  reportId: string;
  preference?: string;
  isRegenerate: boolean;
  searchType?: number;
  producerId?: string;
  status?: string;
  updatedBy?: string;
  filteredDate?: string;
  siteIds?: string;
}

export enum ReportTypes {
  registration = "registration",
  activity = "activity",
  killdata = "killdata",
  preference = "preference",
  pgaAuditLog = "pgaAuditLog",
}
export interface ReportItem {
  reportId: string;
  startDate: string;
  endDate: string;
  country: string;
  site: string;
  preference: string;
  name: string;
  createdDate: string;
  noteProgress: null | string;
  species: null | string;
  typeId: number;
  typeName: keyof typeof ReportTypes;
  subTitle?: string;
  isDownloading?: boolean;
  pGAUpdatedBy: string;
  pGAStatus: string;
  producers: string;
}

export interface IReportCreationErrorMsg {
  sitesMsg?: string;
  countryMsg?: string;
  speciesMsg?: string;
  reportNameMsg?: string;
  errorMsgBottomPopup?: string;
  errorMsgBottomPopupResponse?: string;
  preferenceMsg?: string;
  updatedByMsg?: string;
  statusMsg?: string;
  producerIDMsg?: string;
  producerIDMsgResponse?: string;
}

export interface IRegistrationReportResponse {
  getCmsReportExecution: {
    errorCode: string;
    errorMessage: string;
    responseCode: number;
    fileName: string;
    reportTitle: string;
    totalCount: number;
    totalPage: number;
    registrationReport: IRegistrationReport[];
    activityReport: IActivityReport[];
    marketingReport: IMarketingReport[];
    killDataReport: null;
  };
}

export interface IRegistrationReport {
  producerId: string;
  tradingName: string;
  farmName: string;
  county: string;
  registeredDate: string;
}

export interface IActivityReport {
  buyer: string;
  county: string;
  farmName: string;
  loggedInDate: string;
  producerId: string;
  tradingName: string;
  userType: string;
  sites: string;
}

export interface IMarketingReport {
  producerId: string;
  subscribeCall: string;
  subscribeEmail: string;
  subscribePost: string;
  subscribeText: string;
  tradingName: string;
  __typename: string;
}

export interface ICreateReportResponse {
  createCmsReport: {
    activityReportId: string;
    errorCode: string;
    errorMessage: string;
    responseCode: number;
  };
}
export interface IStatusGeneratingResponse {
  checkStatusCmsReportGenerating: {
    responseCode: number;
    errorCode: string;
    errorMessage: string;
    isGenerating: boolean;
    reportType: number;
  };
}

export enum ErrorCodeResCreateReport {
  CDH_ERR_011 = "CDH_ERR_011",
  CDH_ERR_013 = "CDH_ERR_013",
  COM_ERR_021 = "COM_ERR_021",
  COM_ERR_022 = "COM_ERR_022",
  COM_ERR_023 = "COM_ERR_023",
}

export interface ICreateReportPGAAuditLogResponse {
  createPGAAuditLogReport: {
    errorCode: string;
    errorMessage: string;
    responseCode: number;
    activityReportId: string;
  };
}
