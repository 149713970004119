import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import pgaFormReducer from "./pgaFormReducer";
import reportReducer from "./reportReducer";

export default configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    pgaForm: pgaFormReducer,
    report: reportReducer,
  },
});
