import React, { useReducer, Reducer, useEffect, useCallback, useMemo } from "react";

import { User } from "oidc-client";
import { AuthService, LoggerService } from "services";
import { useSelector } from "react-redux";
import { getClientId } from "store";

export const LOAD_LOGINUSER = "LOAD_LOGINUSER";
export const UNLOAD_LOGINUSER = "UNLOAD_LOGINUSER";
export const LOAD_PERMISSION = "LOAD_PERMISSION";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
interface IContextProviderProps {
  children: React.ReactNode;
}

interface LoginUser {
  userName: string;
  email: string;
}

interface AuthorizeInfo {
  access_token: string;
}

interface AppState {
  userLogin: LoginUser | null;
  authenticated: boolean;
  access_token: string;
  updateToken: (access_token: string) => void;
}

const initialState: AppState = {
  userLogin: null,
  authenticated: false,
  access_token: null,
  updateToken(id: String) {
    throw new Error("AppContext not yet initialized.");
  }
};

const AppContext = React.createContext<AppState>(initialState);
AppContext.displayName = "ApplicationContext";

type Action =
  | { type: typeof LOAD_LOGINUSER; payload: LoginUser }
  | { type: typeof UNLOAD_LOGINUSER }
  | { type: typeof UPDATE_TOKEN; payload: AuthorizeInfo }

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case LOAD_LOGINUSER:
      return {
        ...state,
        userLogin: action.payload,
        authenticated: true,
      };
    case UNLOAD_LOGINUSER:
      return {
        ...state,
        userLogin: null,
        authenticated: false,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        access_token: action.payload.access_token,
      };
    default:
      return state;
  }
};

const AppContextProvider = (props: IContextProviderProps) => {
  let [state, dispatch] = useReducer<Reducer<AppState, Action>>(reducer, initialState);

  const onUserLoaded = () => (user: User) => {
    LoggerService.info("User Loaded: " + JSON.stringify(user));
    dispatch({
      type: LOAD_LOGINUSER,
      payload: {
        userName: user.profile.preferred_username,
        email: user.profile.email,
      },
    });
  };

  const onAccessTokenExpired = () => async () => {
    LoggerService.info("Token expired: " + AuthService.getAccessToken());

    dispatch({
      type: "UNLOAD_LOGINUSER",
    });
    let isSigninSuccess = false;
    let tryCount = 0;
    while (!isSigninSuccess && tryCount < 3) {
      try {
        await AuthService.UserManager.signinSilent();
        isSigninSuccess = true;
      } catch (error) {
        LoggerService.error("Error signing in silently: " + error);
        tryCount++;
      }
    }
  };

  const addOidcEvents = useCallback(() => {
    const oidcEvents = AuthService.UserManager.events;
    oidcEvents.addUserLoaded(onUserLoaded());
    oidcEvents.addAccessTokenExpired(onAccessTokenExpired());
  }, []);

  const removeOidcEvents = useCallback(() => {
    const oidcEvents = AuthService.UserManager.events;
    oidcEvents.removeUserLoaded(onUserLoaded());
    oidcEvents.removeAccessTokenExpired(onAccessTokenExpired());
  }, []);

  const updateToken = useCallback((access_token: string) => {
    dispatch({
      type: UPDATE_TOKEN,
      payload: {
        access_token: access_token,
      },
    });
  }, []);

  const value: AppState = useMemo(
    () => ({
      ...state,
      updateToken,
    }),
    [state, updateToken]
  );

  const clientId = useSelector(getClientId);
  const relogin = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (clientId && clientId !== "S5E-CMSSPA") {
      relogin();
    }
  }, [clientId]);

  useEffect(() => {
    addOidcEvents();
    AuthService.UserManager.getUser().then((oidcUser) => {
      if (oidcUser && !oidcUser!.expired) {
        dispatch({
          type: LOAD_LOGINUSER,
          payload: {
            userName: oidcUser.profile.preferred_username,
            email: oidcUser.profile.email,
          },
        });
      }
    });
    return () => removeOidcEvents();
  }, [addOidcEvents, removeOidcEvents]);

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};
export { AppContext, AppContextProvider };
