import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "helpers";

export const userReducer = createSlice({
  name: "user store",
  initialState: {
    roles: [],
    userInfo: {
      name: "",
      email: "",
      user_id: "",
    },
    roleBuyer: false,
  },
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setRoles, setUserInfo } = userReducer.actions;

export const getRoles = (state: IStore) => state.user.roles;
export const getUserInfo = (state: IStore) => state.user.userInfo;

export default userReducer.reducer;
