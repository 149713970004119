import React from "react";
// import { Spinner } from "reactstrap";

const loadingStyle = {
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center"
};

const Loader = () => {
  return (
    <div style={loadingStyle}>
      {/* <Spinner type="grow" color="primary" style={{ width: "3rem", height: "3rem" }} />
      <Spinner type="grow" color="secondary" style={{ width: "3rem", height: "3rem" }} />
      <Spinner type="grow" color="success" style={{ width: "3rem", height: "3rem" }} /> */}
    </div>
  );
};

export default Loader;