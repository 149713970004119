import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "helpers";

export const pgaFormReducer = createSlice({
  name: "pga form store",
  initialState: {
    isNavigated: false,
    logoutConfirmationClicked: true,
    filterPGAForm: null,
  },
  reducers: {
    setIsReadyNavigated: (state, action) => {
      state.isNavigated = action.payload;
    },
    setLogoutConfirmationClicked: (state, action) => {
      state.logoutConfirmationClicked = action.payload;
    },
    setFilterPGAForm: (state, action) => {
      state.filterPGAForm = action.payload;
    },
  },
});

export const {
  setIsReadyNavigated,
  setLogoutConfirmationClicked,
  setFilterPGAForm,
} = pgaFormReducer.actions;

export const getIsReadyNavigated = (state: IStore) => state.pgaForm.isNavigated;
export const getLogoutConfirmationClicked = (state: IStore) =>
  state.pgaForm.logoutConfirmationClicked;
export const getFilterPGAForm = (state: IStore) => state.pgaForm.filterPGAForm;

export default pgaFormReducer.reducer;
