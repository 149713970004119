import * as Yup from "yup";

export const defaultSchema = Yup.object().default({});

export const step1ValidationSchema = Yup.object().shape({
  GroupName: Yup.string()
    .required("Required information")
    .max(20, "Group Name max length is 20 characters.")
    .matches(
      /^[A-Za-z0-9 _,.()-]*$/,
      "Group Name must be letters, numbers, space and ( ) _ - , ."
    ),
  GroupDescription: Yup.string()
    .nullable()
    .max(200, "Group Description max length is 200 characters."),
  Site: Yup.array().nullable().required("Required information"),
  SpecIdCategories: Yup.number().required("Required information"),
  SpecId: Yup.array().nullable().required("Required information"),
});

export const step2ValidationSchema = Yup.object().shape({
  ProducerIdSelect: Yup.array().nullable().required("Required information"),
});

const idealRange = {
  conf: [
    {
      value: "P-",
      orderPos: 1,
    },
    {
      value: "P=",
      orderPos: 2,
    },
    {
      value: "P+",
      orderPos: 3,
    },
    {
      value: "O-",
      orderPos: 4,
    },
    {
      value: "O=",
      orderPos: 5,
    },
    {
      value: "O+",
      orderPos: 6,
    },
    {
      value: "R-",
      orderPos: 7,
    },
    {
      value: "R=",
      orderPos: 8,
    },
    {
      value: "R+",
      orderPos: 9,
    },
    {
      value: "U-",
      orderPos: 10,
    },
    {
      value: "U=",
      orderPos: 11,
    },
    {
      value: "U+",
      orderPos: 12,
    },
    {
      value: "E-",
      orderPos: 13,
    },
    {
      value: "E=",
      orderPos: 14,
    },
    {
      value: "E+",
      orderPos: 15,
    },
  ],
  fat: [
    {
      value: "1-",
      orderPos: 1,
    },
    {
      value: "1=",
      orderPos: 2,
    },
    {
      value: "1+",
      orderPos: 3,
    },
    {
      value: "2-",
      orderPos: 4,
    },
    {
      value: "2=",
      orderPos: 5,
    },
    {
      value: "2+",
      orderPos: 6,
    },
    {
      value: "3-",
      orderPos: 7,
    },
    {
      value: "3=",
      orderPos: 8,
    },
    {
      value: "3+",
      orderPos: 9,
    },
    {
      value: "4-",
      orderPos: 10,
    },
    {
      value: "4=",
      orderPos: 11,
    },
    {
      value: "4+",
      orderPos: 12,
    },
    {
      value: "5-",
      orderPos: 13,
    },
    {
      value: "5=",
      orderPos: 14,
    },
    {
      value: "5+",
      orderPos: 15,
    },
  ],
};

export const step3ValidationSchema = Yup.object().shape({
  bullWeightMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "bullWeightMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullWeightMax } = this.parent;
        return bullWeightMax ? value !== null : true;
      }
    ),
  bullWeightMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "bullWeightMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullWeightMin } = this.parent;
        return bullWeightMin ? value !== null : true;
      }
    )
    .test(
      "bullWeightMax",
      "Min value and Max value must be different",
      function (value) {
        const { bullWeightMin } = this.parent;
        return value === null || bullWeightMin !== value;
      }
    )
    .test(
      "bullWeightMax",
      "Max value must be larger than Min value",
      function (value) {
        const { bullWeightMin } = this.parent;
        return value === null || value > bullWeightMin;
      }
    ),
  bullAgeMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "bullAgeMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullAgeMax } = this.parent;
        return bullAgeMax ? value !== null : true;
      }
    ),
  bullAgeMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "bullAgeMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullAgeMin } = this.parent;
        return bullAgeMin ? value !== null : true;
      }
    )
    .test(
      "bullAgeMax",
      "Min value and Max value must be different",
      function (value) {
        const { bullAgeMin } = this.parent;
        return value === null || bullAgeMin !== value;
      }
    )
    .test(
      "bullAgeMax",
      "Max value must be larger than Min value",
      function (value) {
        const { bullAgeMin } = this.parent;
        return value === null || value > bullAgeMin;
      }
    ),
  bullConfMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "bullConfMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullConfMax } = this.parent;
        return bullConfMax ? !!value : true;
      }
    ),
  bullConfMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "bullConfMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullConfMin } = this.parent;
        return bullConfMin ? !!value : true;
      }
    )
    .test(
      "bullConfMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { bullConfMin } = this.parent;
        return bullConfMin !== value;
      }
    )
    .test(
      "bullConfMax",
      "Max value must be better than Min value",
      function (value) {
        const { bullConfMin } = this.parent;
        if (!value || !bullConfMin) {
          return true;
        }
        const minOrderPos = idealRange.conf.find(
          (item) => item.value === bullConfMin
        )?.orderPos;
        const maxOrderPos = idealRange.conf.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
  bullFatMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "bullFatMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullFatMax } = this.parent;
        return bullFatMax ? !!value : true;
      }
    ),
  bullFatMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "bullFatMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { bullFatMin } = this.parent;
        return bullFatMin ? !!value : true;
      }
    )
    .test(
      "bullFatMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { bullFatMin } = this.parent;
        return bullFatMin !== value;
      }
    )
    .test(
      "bullFatMax",
      "Max value must be better than Min value",
      function (value) {
        const { bullFatMin } = this.parent;
        if (!value || !bullFatMin) {
          return true;
        }
        const minOrderPos = idealRange.fat.find(
          (item) => item.value === bullFatMin
        )?.orderPos;
        const maxOrderPos = idealRange.fat.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
  steerWeightMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "steerWeightMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerWeightMax } = this.parent;
        return steerWeightMax ? value !== null : true;
      }
    ),
  steerWeightMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "steerWeightMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerWeightMin } = this.parent;
        return steerWeightMin ? value !== null : true;
      }
    )
    .test(
      "steerWeightMax",
      "Min value and Max value must be different",
      function (value) {
        const { steerWeightMin } = this.parent;
        return value === null || steerWeightMin !== value;
      }
    )
    .test(
      "steerWeightMax",
      "Max value must be larger than Min value",
      function (value) {
        const { steerWeightMin } = this.parent;
        return value === null || value > steerWeightMin;
      }
    ),
  steerAgeMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "steerAgeMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerAgeMax } = this.parent;
        return steerAgeMax ? value !== null : true;
      }
    ),
  steerAgeMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "steerAgeMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerAgeMin } = this.parent;
        return steerAgeMin ? value !== null : true;
      }
    )
    .test(
      "steerAgeMax",
      "Min value and Max value must be different",
      function (value) {
        const { steerAgeMin } = this.parent;
        return value === null || steerAgeMin !== value;
      }
    )
    .test(
      "steerAgeMax",
      "Max value must be larger than Min value",
      function (value) {
        const { steerAgeMin } = this.parent;
        return value === null || value > steerAgeMin;
      }
    ),
  steerConfMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "steerConfMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerConfMax } = this.parent;
        return steerConfMax ? !!value : true;
      }
    ),
  steerConfMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "steerConfMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerConfMin } = this.parent;
        return steerConfMin ? !!value : true;
      }
    )
    .test(
      "steerConfMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { steerConfMin } = this.parent;
        return steerConfMin !== value;
      }
    )
    .test(
      "steerConfMax",
      "Max value must be better than Min value",
      function (value) {
        const { steerConfMin } = this.parent;
        if (!value || !steerConfMin) {
          return true;
        }
        const minOrderPos = idealRange.conf.find(
          (item) => item.value === steerConfMin
        )?.orderPos;
        const maxOrderPos = idealRange.conf.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
  steerFatMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "steerFatMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerFatMax } = this.parent;
        return steerFatMax ? !!value : true;
      }
    ),
  steerFatMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "steerFatMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { steerFatMin } = this.parent;
        return steerFatMin ? !!value : true;
      }
    )
    .test(
      "steerFatMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { steerFatMin } = this.parent;
        return steerFatMin !== value;
      }
    )
    .test(
      "steerFatMax",
      "Max value must be better than Min value",
      function (value) {
        const { steerFatMin } = this.parent;
        if (!value || !steerFatMin) {
          return true;
        }
        const minOrderPos = idealRange.fat.find(
          (item) => item.value === steerFatMin
        )?.orderPos;
        const maxOrderPos = idealRange.fat.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
  heiferWeightMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "heiferWeightMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferWeightMax } = this.parent;
        return heiferWeightMax ? value !== null : true;
      }
    ),
  heiferWeightMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(250, " must be numbers from 250 to 500")
    .max(500, " must be numbers from 250 to 500")
    .test(
      "heiferWeightMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferWeightMin } = this.parent;
        return heiferWeightMin ? value !== null : true;
      }
    )
    .test(
      "heiferWeightMax",
      "Min value and Max value must be different",
      function (value) {
        const { heiferWeightMin } = this.parent;
        return value === null || heiferWeightMin !== value;
      }
    )
    .test(
      "heiferWeightMax",
      "Max value must be larger than Min value",
      function (value) {
        const { heiferWeightMin } = this.parent;
        return value === null || value > heiferWeightMin;
      }
    ),
  heiferAgeMin: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "heiferAgeMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferAgeMax } = this.parent;
        return heiferAgeMax ? value !== null : true;
      }
    ),
  heiferAgeMax: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return !isNaN(value) ? value : null;
    })
    .nullable()
    .min(12, " must be numbers from 12 to 72")
    .max(72, " must be numbers from 12 to 72")
    .test(
      "heiferAgeMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferAgeMin } = this.parent;
        return heiferAgeMin ? value !== null : true;
      }
    )
    .test(
      "heiferAgeMax",
      "Min value and Max value must be different",
      function (value) {
        const { heiferAgeMin } = this.parent;
        return value === null || heiferAgeMin !== value;
      }
    )
    .test(
      "heiferAgeMax",
      "Max value must be larger than Min value",
      function (value) {
        const { heiferAgeMin } = this.parent;
        return value === null || value > heiferAgeMin;
      }
    ),
  heiferConfMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "heiferConfMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferConfMax } = this.parent;
        return heiferConfMax ? !!value : true;
      }
    ),
  heiferConfMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.conf.map((item) => item.value),
      " only accepts values {P-; P=; P+; O-; O=; O+; R-; R=; R+; U-; U=; U+; E-; E=; E+}"
    )
    .test(
      "heiferConfMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferConfMin } = this.parent;
        return heiferConfMin ? !!value : true;
      }
    )
    .test(
      "heiferConfMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { heiferConfMin } = this.parent;
        return heiferConfMin !== value;
      }
    )
    .test(
      "heiferConfMax",
      "Max value must be better than Min value",
      function (value) {
        const { heiferConfMin } = this.parent;
        if (!value || !heiferConfMin) {
          return true;
        }
        const minOrderPos = idealRange.conf.find(
          (item) => item.value === heiferConfMin
        )?.orderPos;
        const maxOrderPos = idealRange.conf.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
  heiferFatMin: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "heiferFatMin",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferFatMax } = this.parent;
        return heiferFatMax ? !!value : true;
      }
    ),
  heiferFatMax: Yup.string()
    .transform((value, originalValue) => {
      return typeof originalValue === "string" ? value : null;
    })
    .oneOf(
      idealRange.fat.map((item) => item.value),
      " only accepts values {1-; 1=; 1+; 2-; 2=; 2+; 3-; 3=; 3+; 4-; 4=; 4+; 5-; 5=; 5+}"
    )
    .test(
      "heiferFatMax",
      "Please enter data for both Min value and Max value or leave all blank",
      function (value) {
        const { heiferFatMin } = this.parent;
        return heiferFatMin ? !!value : true;
      }
    )
    .test(
      "heiferFatMax",
      "Min value and Max value must be different",
      function (value) {
        if (!value) {
          return true;
        }
        const { heiferFatMin } = this.parent;
        return heiferFatMin !== value;
      }
    )
    .test(
      "heiferFatMax",
      "Max value must be better than Min value",
      function (value) {
        const { heiferFatMin } = this.parent;
        if (!value || !heiferFatMin) {
          return true;
        }
        const minOrderPos = idealRange.fat.find(
          (item) => item.value === heiferFatMin
        )?.orderPos;
        const maxOrderPos = idealRange.fat.find(
          (item) => item.value === value
        )?.orderPos;
        return maxOrderPos > minOrderPos;
      }
    ),
});
