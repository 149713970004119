export enum statusPGAForm {
  NOT_STARTED = "NotStarted",
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
}

export interface ResponseGetAgreementFormsList {
  errorCode: string;
  errorMessage: string;
  responseCode: number;
  forms: AgreementFormItem[];
  totalCountCurrentTab: number;
  totalNotStarted: number;
  totalDraft: number;
  totalSubmitted: number;
  totalApproved: number;
}

export interface AgreementFormItem {
  producerId: string;
  agreementFormId: string;
  tradingName: string;
  holdingNumber: string;
  lastUpdated: string;
  expiredIn: string;
  sites: string;
  isReviewed: boolean;
  lastUpdatedRole: string;
}

export interface IExpiredDropDownList {
  label: string;
  id: string;
}

export interface IPGAFormFilter {
  siteIds: string;
  expired: string;
  producerId: string;
  holdingNumber: string;
  status: string;
  isDownload: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface DropdownListPGAForm {
  farmAssuranceSchemes: DropdownItemPGAForm[];
  organicSchemes: DropdownItemPGAForm[];
  environmentalSchemes: DropdownItemPGAForm[];
  beddingMaterials?: CheckboxItemPGAForm[];
  forecastedDates?: CheckboxItemPGAForm[];
  forecastedTypesCattle?: CheckboxItemPGAForm[];
  forecastedTypesSheep?: CheckboxItemPGAForm[];
  soyaOrigins?: DropdownItemPGAForm[];
  palmOrigins?: DropdownItemPGAForm[];
  sheepBreeds?: DropdownItemPGAForm[];
  farmEnterprises?: CheckboxItemPGAForm[];
  farmVetPractices?: DropdownItemPGAForm[];
  semenSuppliers?: DropdownItemPGAForm[];
  bioDiversities?: DropdownItemPGAForm[];
  carbonCalculators?: DropdownItemPGAForm[];
  carbonTechniques?: DropdownItemPGAForm[];
  carbonLatestYears?: DropdownItemPGAForm[];
  carbonFootprintUnits?: DropdownItemPGAForm[];
  interestedInOptions?:CheckboxItemPGAForm[];
}

export interface DropdownItemPGAForm {
  label: string;
  value: number;
}

export interface SelectItemPGAForm {
  label: string;
  value: boolean;
}

export interface CheckboxItemPGAForm {
  id: number;
  name: string;
}

export interface PGAFormReview {
  agreementStep1: AggrementStep1Dto;
  agreementStep2: AggrementStep2Dto;
  differentFields: string;
  approvedFields: string;
  isApprovedAll: boolean;
  errorCode: null | string;
  errorMessage: null | string;
  responseCode: number;
}

export interface AggrementStep1Dto {
  additionalContactNumber: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  alternateEmailAddress: string;
  contactName: string;
  countryName: string;
  county: string;
  mainContactNumber: string;
  paymentAddressLine1: string;
  postCode: string;
  primaryEmailAddress: string;
  tradingName: string;
}

export interface AggrementStep2Dto {
  farmAssuraceSchemeId: number;
  farmAssuraceOtherValues: string;
  fasMembershipNo: string;
  primaryHoldingNumber: string;
  additionalHoldingNumber: string;
  organicProducer: boolean;
  organicSchemeId: number;
  organicSchemeOtherValue: string;
  organicSchemeNumber: string;
  environmentalScheme: boolean;
  environmentalSchemeId: number;
  environmentalSchemeOtherValue: string;
  uploadAntibioticData: boolean;
}

export interface AggrementStep3Dto {
  approveFinishingUnit: boolean;
  beddingMaterialId: any;
  beddingMaterialOtherValue: string;
  cattleToFinish: boolean;
  feedContainPalmOil: boolean;
  feedContainSoya: boolean;
  feedInfoObject: string;
  forecastedSupplyObject: string;
  herdPrefix: string;
  homeBredCattle: boolean;
  numbersPurchasedObject: string;
  palmFeedingObject: string;
  soyaFeedingObject: string;
  supplyCattle: boolean;
  unitGrazing: boolean;
  grassDietPercentage: number;
  cattleOutdoorsInWinter: boolean;
  finishCattleForSlaughter: boolean;
  __typename?: string;
}

export interface AggrementStep4Dto {
  finishLambForSlaughter: boolean;
  eweBreedIds: string;
  eweOther: string;
  feedContainPalmOil: boolean;
  feedContainSoya: boolean;
  feedInfoObject: string;
  flockNumber: string;
  additionalFlockNumber: string;
  flockSize: number;
  forecastedSupplyObject: string;
  geneticLambs: boolean;
  palmFeedingObject: string;
  ramBreedIds: string;
  ramOther: string;
  soyaFeedingObject: string;
  supplySheep: boolean;
  __typename?: string;
}

export interface CurrentAggrementForm {
  agreementFormId: string;
  agreementStep1Dto: AggrementStep1Dto;
  agreementStep2Dto: AggrementStep2Dto;
  agreementStep3Dto: AggrementStep3Dto;
  agreementStep4Dto: AggrementStep4Dto;
  agreementStep5Dto: AggrementStep5Dto;
  agreementStep6Dto: AggrementStep6Dto;
  agreementStep7Dto: AggrementStep7Dto;
  currentStep: number;
  displayStep: number;
  errorCode?: string | null;
  errorMessage?: string | null;
  producerId?: string;
  producerName?: string;
  progressStatusId?: number;
  progressStatusName?: string;
  responseCode?: number;
}

export interface AggrementStep5Dto {
  additionalFarmEnterprise: boolean;
  farmEnterpriseIds: string;
  farmEnterpriseOther: string;
  farmVetPractice: number;
  farmVetOtherName: string;
  farmVetOtherAdd: string;
  farmVetOtherPostCode: string;
  farmVetOtherPhoneNo: string;
  useAI: boolean;
  companySemenSupplyIds: string;
  companySemenSupplyOther: string;
  companyAIServiceIds: string;
  companyAIServiceOther: string;
  financeForWeanedCalves: boolean;
  interestedInOptions: string;
  interestedInResearchOrProducerGroup: boolean;
  __typename?: string;
}

export interface FormStep1Values {
  contactName: string;
  tradingName: string;
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  county: DropdownItemPGAForm;
  postCode: string;
  country: string;
  email: string;
  emails: { name: string }[];
  phone: string;
  phones: { name: string }[];
}

export interface FormStep2Values {
  farmAssuranceScheme: DropdownItemPGAForm;
  otherFASValue: string;
  membershipNo: string;
  holdingNumber: string;
  holdingNumbers: { name: string }[];
  organicProducer: SelectItemPGAForm;
  organicScheme: DropdownItemPGAForm;
  otherOSValue: string;
  organicSchemeNo: string;
  anyEnvironmental: SelectItemPGAForm;
  environmentalScheme: DropdownItemPGAForm;
  otherESValue: string;
  uploadAntibioticData: SelectItemPGAForm;
}

export interface FormStep3Values {
  supplyCattle: SelectItemPGAForm;
  herdPrefix: string;
  finishHomeBredCattle: SelectItemPGAForm;
  cattleToFinish: SelectItemPGAForm;
  numbersPurchased: ItemNumbersPurchased[];
  whatBedding: string;
  otherBeddingMateria: string;
  forecastedSupply: ItemForecastedSupply[];
  approvedFinishingUnit: SelectItemPGAForm;
  unitHaveGrazing: SelectItemPGAForm;
  grassDietPercentage: number;
  feedInformation: ItemFeedInformation[];
  feedContainSoya: SelectItemPGAForm;
  soyaFeedingObject: ItemSoyaFeeding[];
  feedContainPalm: SelectItemPGAForm;
  palmFeedingObject: ItemPalmFeeding[];
  finishCattleForSlaughter: SelectItemPGAForm;
  cattleOutdoorsInWinter: SelectItemPGAForm;
}

export interface FormStep4Values {
  finishLambs: SelectItemPGAForm;
  supplySheep: SelectItemPGAForm;
  flockNumber: string;
  flockNumbers: { name: string }[];
  feedInformation: ItemFeedInformation[];
  feedContainSoya: SelectItemPGAForm;
  soyaFeeding: ItemSoyaFeeding[];
  estimatedBreeding: number;
  ewe: DropdownItemPGAForm[];
  eweOther: string;
  ram: DropdownItemPGAForm[];
  ramOther: string;
  forecastedSupply: ItemForecastedSupply[];
  highGeneticProgram: SelectItemPGAForm;
  feedContainPalmOil: SelectItemPGAForm;
  palmFeeding: ItemPalmFeeding[];
}

export interface FormStep5Values {
  checkboxFarmEnterprises: CheckboxItemPGAForm[];
  farmEnterprises: SelectItemPGAForm;
  farmVetPractices: DropdownItemPGAForm;
  otherFVPAddress: string;
  otherFVPName: string;
  otherFVPPhoneNumber: string;
  otherFVPPostcode: string;
  otherFarmEnterprises: string;
  otherProvidesAIService: string;
  otherSemenSuppliers: string;
  providesAIService: DropdownItemPGAForm;
  semenSuppliers: DropdownItemPGAForm;
  useAI: SelectItemPGAForm;
  weanedCalves: SelectItemPGAForm;
  checkboxInterestedInOption: CheckboxItemPGAForm[];
  interestedInResearchOrProducerGroup: SelectItemPGAForm;
}

export interface ItemFeedInformation {
  feedIngredient: string;
  homegrown: boolean;
  supplier: string;
  certNumber: string;
}


export interface IForecastedSupply {
  typeId: number;
  dateId: number;
  value: number | null;
}

export interface IItemNumbersPurchasedObject {
  typeName: string;
  catName: string;
  value: number;
}

export interface ItemNumbersPurchased {
  typeName: string;
  catName: string;
  value: number;
}

export interface ItemForecastedSupply {
  typeId: number;
  dateId: number;
  value: number;
}

export interface ItemSoyaFeeding {
  soyaOrigin: DropdownItemPGAForm | null;
  gmo: boolean;
  soyaQuantity: string;
}

export interface ItemPalmFeeding {
  palmOrigin: DropdownItemPGAForm | null;
  palmQuantity: string;
}

export interface IMatrixTableCell {
  value: number | null;
}

export interface ItemColFS {
  typeId: number;
  dateId: number;
  value: number;
}

export interface ItemDuplicateError {
  isDuplicate: ItemDuplicateDetailError;
}

export interface ItemDuplicateDetailError {
  message: string;
  ref: undefined;
  type: string;
  row?: number;
}

export interface HeaderRow {
  id: number;
  name: string;
  colSpan?: number;
}

export interface ItemFootprintResult {
  unit: DropdownItemPGAForm | null;
  quantity: string;
}

export interface FormStep6Values {
  biodiversity: DropdownItemPGAForm[];
  otherBiodiversity: string;
  soilPlan: SelectItemPGAForm;
  waterPlan: SelectItemPGAForm;
  renewableEnergy: SelectItemPGAForm;
  measuredCarbon: SelectItemPGAForm;
  carbonCalculator: DropdownItemPGAForm;
  otherCarbonCalculator: string;
  yearCalculated: DropdownItemPGAForm;
  footprintResult: ItemFootprintResult[];
  carbonReductionPlan: SelectItemPGAForm;
  carbonReductionTechniques: DropdownItemPGAForm[];
  otherCarbonReductionTechniques: string;
  interestedInSustainabilityProgramme: SelectItemPGAForm;
  peatExposure: SelectItemPGAForm;
  peatReductionPlan: SelectItemPGAForm;
  sellCarbonCredit: SelectItemPGAForm;
}

export interface AggrementStep6Dto {
  biodiversityIds: string;
  bioDiversityOtherValue: string;
  soilManagementPlan: boolean;
  waterManagementPlan: boolean;
  renewableEnergy: boolean;
  measuredCarbonFootprint: boolean;
  carbonCaculatorId: number;
  carbonCaculatorOtherValue: string;
  carbonFootprintCalculatedYear: number;
  carbonFootprintResultObject: string;
  carbonReductionPlan: boolean;
  carbonReductionPlanIds: string;
  carbonReductionPlanOtherValue: string;
  interestedInSustainabilityProgramme: boolean;
  peatExposure: boolean;
  peatReductionPlan: boolean;
  sellCarbonCredit: boolean;
  __typename?: string;
}

export interface AggrementStep7Dto {
  signature: string;
}

export interface FormStep7Values {
  signature: string;
}
