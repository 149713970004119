export const ErrorMessages = {
  MAX_LENGTH: "Title max length is 50 characters",
  TITLE_REGEX: "Title must be letters, numbers, space and ( ) _ - , .",
  TITLE_REGEX_ALERT: "Title must be letters, numbers, space and ( ) _ - , . & ' \"",
  REPORT_NAME_REGEX:
    "Report name must be letters, numbers, space and ( ) _ - , .",
  INCLUDED_INVALID_PRODUCER_IDS: "You have included invalid Producer IDs",
  SORRY_ERROR: "We’re really sorry!",
  SMT_WRONG_ERROR:
    "Something must have gone wrong. Please reload this page and try again!",
  SUB_TITLE_MAX_LENGTH: "Sub-title max length is 100 characters",
  SUB_TITLE_REGEX: "Sub-title must be letters, numbers, space and ( ) _ - , .",
  ERROR_REGEX: "must be letters, numbers, space and ( ) _ - , .",
  ERROR_MAX_LENGTH: "Exceed max length (50)",
  REPORT_NAME_MAX_LENGTH: "Report name max length is 50 characters",
  REQUIRE_INFORMATION: "Required information",
  ERROR_KILL_DATA: "Please select one of the filters Country, Site, Species.",
  ERROR_ALREADY_APPROVE:
    "The form has already been approved. Please refresh page to get latest data.",
  ERROR_UPDATE: "The form has been updated. Please refresh page and try again",
  ERROR_ALREADY_UPDATE:
    "The form has already been updated. Please refresh the page to get the latest data.",
  ERROR_BLANK_COUNTRY_OR_SITE:
    "Please select at least one of the filters Country or Site.",
  ERROR_MAX_ROW_PRODUCER_IDS:
    "You have included too many Producer IDs. Maximum number is 500",
  ERROR_DUPLICATED_PRODUCER_IDS: "You have included duplicated Producer IDs",
};

export const SuccessMessages = {
  TITLE_MES_SUCCESS: "Congratulations!",
  CONTENT_MES_SUCCESS: "Your document has been uploaded.",
  UPDATE_ROLE_SUCCESS: "This account's access has been updated.",
  ASSIGN_SITES_SUCCESS: "The selected sites have been assigned to this Buyer.",
  ASSIGN_SITES_SUCCESS_AM: "The selected sites have been assigned to this Agriculture Manager.",
  PGA_FORM_LIST_SUCCESS: "The PGA Form(s) has been approved successfully.",
  PGA_FORM_RESET_SUCCESS: "The PGA form has been reset successfully to be Not Started status.",
};

export const EditedFarmGroupMessage = {
  INFORMATION_HEADING: "Information",
  VIEWING_UPDATED_GROUP_ERROR: " It seems that the group has just been updated. Please refresh the page to get the latest data.",
  EDITING_UPDATED_GROUP_ERROR: "Sorry, this group has been updated. Please refresh to get the latest data.",
};