export const NOT_YET_SUPPLIED = "(Not yet supplied)";
export const NOT_YET_SUPPLIED_ID = "NotYetSupplied";
export const NO_COUNTRIES = "No countries";
export const NO_SITES = "No sites";
export const NONE = "None";

export const REPORT_TYPE_NAME = {
  killdata: "Kill Data",
  registration: "Registration",
  activity: "Activity",
  preference: "Marketing Preference",
  pgaAuditLog: "Audit Log - PGA Form",
};

export const NOTE_PROGRESS = {
  generating: "Generating",
  failed: "Failed to generate",
};

export const listStatusPGAForm = [
  { label: "Submitted for approval", id: "2" },
  { label: "Approved", id: "3" },
];
